<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs1>
        <v-subheader><v-icon>{{ getIcon }}</v-icon></v-subheader>
      </v-flex>
      <v-flex xs2>
        <v-subheader>{{ document.friendly }} {{ document.status }}</v-subheader>
      </v-flex>
      <v-flex xs8>
        <v-subheader v-if="showEnrolled">
          <v-text-field
            label="Enrolled Date"
            :value="document.enrollTimestamp"
            disabled
          ></v-text-field>
      </v-subheader>
      </v-flex>
    </v-layout>
    <v-layout v-if="showEnrolled" row>
      <v-flex xs1 />
      <v-flex xs2><v-subheader>Available Files</v-subheader></v-flex>
      <v-flex xs8 class="pt-3">
        <v-layout row>
          <v-flex xs2>--</v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import BiometricMap from '@/utils/BiometricMap';

export default {
  name: 'BasePinCapture',
  props: {
    document: Object,
  },
  computed: {
    showEnrolled() {
      return this.document.status === 'Enrolled';
    },
    getIcon() {
      const { icon } = BiometricMap.get(this.document.captureType);
      return icon;
    },
  },
};
</script>
